import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormFeedback, FormGroup, Input, Media, Row,
} from 'reactstrap';
import * as yup from 'yup';
import { Field, Formik, Form } from 'formik';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/arrow_transparent.svg';
import { postProductContact } from '../utils/Api';

// Client side props input validation
const SignupSchema = yup.object().shape({
  name: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .max(50, 'Debe contener menos de 50 caracteres.')
    .required('Requerido *'),
  message: yup.string()
    .min(10, 'Debe contener más de 10 caracteres.')
    .max(250, 'Debe contener menos de 250 caracteres.')
    .required('Requerido *'),
  email: yup.string()
    .email('Correo electrónico inválido.')
    .required('Requerido *'),
  city: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .required('Requerido *'),
});

// <Formik <Field component={customInputForm} /> />
const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

class ProductForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      msg: '',
      isSubmitting: false,
    };
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  render() {
    const {
      brand, productName,
    } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
          name: '',
          message: '',
          city: '',
          phone: '',
          brand_name: brand,
          product_name: productName,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          // :service=>"BENELLI STORE"
          // values.id_brand = brand.toUpperCase();

          // needed by Newsletter.create
          values.section_page = window.location.pathname;
          values.brand_name = brand.toUpperCase();
          values.product_name = window.location.pathname === "/"
            ? "AGROBIKES"
            : (window.location.pathname.split("/").length > 2
              ? window.location.pathname.split("/")[2].toUpperCase()
              : "")
          ;

          // Custom jsonPostData JSON form data defined inside so onSubmit={(values)} can be used
          const jsonPostData = {
            section_page: values.section_page,
            brand_name: values.brand_name,
            product_name: values.product_name,
            // Server key for Newsletter.create
            newsletter: {
              message: values.message,
              name: values.name,
              email: values.email,
              phone: values.phone,
              city: values.city,
            },
            // Client key ADF Tecnom
            prospect: {
              requestdate: new Date().toISOString(),
              customer: {
                comments: values.message,
                contacts: [
                  {
                    emails: [{ value: values.email }],
                    names: [
                      { part: "first", value: values.name },
                      ...(values.lastname ? [{ part: "last", value: values.lastname }] : []),
                    ],
                    phones: values.phone ? [{ type: "cellphone", value: values.phone }] : [],
                    addresses: [
                      {
                        city: values.city,
                        ...(values.zipcode ? { postalcode: values.zipcode } : {}),
                      },
                    ],
                  },
                ],
              },
              vehicles: values.brand_name
                ? [
                    {
                      make: values.brand_name,
                      model: values.product_name || "Unknown Model",
                      trim: "Version",
                      year: 2017,
                    },
                  ]
                : [],
              provider: {
                name: { value: "WEBSITE" },
                service: values.brand_name,
              },
              vendor: {
                contacts: [],
                vendorname: { value: "vendedor@email.com.ar" },
              },
            },
          };

          this.setSubmitting(false);

          // Custom jsonPostData JSON form data
          postProductContact(jsonPostData).then(response => {
            // not submitted yet
            this.setSubmitting(true);
            // now submitted
            if (typeof response.data === 'string') {
              this.setState({ msg: response.data });
            } else {
              this.setState({ msg: 'Su mensaje ha sido enviado exitosamente!!' });
            }
            actions.resetForm({ values: '' });
          }).catch(error => {
            this.setState({ msg: error.data });
          });

          // Default was
          /*
          postProductContact(values).then((response) => {
            this.setSubmitting(true);
            if (typeof response.data === 'string') {
              this.setState({ msg: response.data });
            } else {
              this.setState({ msg: 'Su mensaje a sido enviado exitosamente!!' });
            }
            actions.resetForm({ values: '' });
          }).catch((error) => {
            this.setState({ msg: error.data });
          });
          */
        }}
      >
        {(props) => (
          <span>
            {this.state.msg && (
              // <Media tag="h6" className="alert alert-success mt-2">
              //   {this.state.msg}
              // </Media>
              <Media tag="h6" className="alert alert-success mt-2" id="consulta_moto">
                 {this.state.msg}
              </Media>
            )}
            {/* <form> POST action="/product_contact" */}
            <Form className="mt-5 form-product" data-type="product" onSubmit={props.handleSubmit} action="/product_contact">
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field name="name" type="text" component={customInputForm} placeholder="NOMBRE *" required className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="email" name="email" component={customInputForm} placeholder="CORREO ELECTRONICO *" required className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="text" name="phone" component={customInputForm} placeholder="NUMERO DE TELEFONO" className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="text" name="city" component={customInputForm} placeholder="CIUDAD/PROVINCIA *" required className="input-form" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Field type="textarea" name="message" component={customInputForm} placeholder="MENSAJE *" required className="input-form" />
              </FormGroup>
              <Row>
                <Col
                  xs={{ size: 8, offset: 2 }}
                  md={{ size: 6, offset: 6 }}
                  lg={{ size: 4, offset: 8 }}
                >
                  <FormGroup>
                    <Button className="enter dark align-items-center mt-4 w-100 border-0 ">
                      <div className="square">
                        <Media src={arrow} className="w-100" width="100" height="100" />
                      </div>
                      <Media tag="h3" className="my-0 mx-0 align-items-center justify-content-center h-100">ENVIAR</Media>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </span>
        )}
      </Formik>
    );
  }
}

ProductForm.propTypes = {
  brand: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};

export default ProductForm;
